import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function initializeScrollSections() {
    const scrollBlocks = document.querySelectorAll('.block-scroll-sections-tabs');

    scrollBlocks.forEach((block) => {
        new ScrollSectionsClass(block);
    });
}

class ScrollSectionsClass {
    constructor(block) {
        this.block = block;
        this.blockElementList = this.block.querySelector('.block-scroll-sections-tabs__list-elements');
        this.navContainer = this.block.querySelector('.block-scroll-sections-tabs__nav-container');
        this.navItems = [];
        this.sections = [];
        this.init();
    }

    init() {
        if (window.innerWidth < 768) {
            return;
        }

        this.sections = Array.from(this.blockElementList.children).filter((section) => {
            return (
                section.tagName.toLowerCase() === 'section' &&
                section.hasAttribute('data-nav-title')
            );
        });

        if (!this.sections.length) return;
        this.renderNav();

        setTimeout(() => {
            this.activateSection(0);
        }, 50);

        this.setupScrollTriggers();
    }

    renderNav() {
        const nav = this.navContainer.querySelector('.block-scroll-sections-tabs__nav');

        nav.innerHTML = this.sections
            .map((section, index) => {
                return `
          <div class="block-scroll-sections-tabs__nav-item pb-6 pb-lg-12" data-index="${index}">
            <h4 class="mb-4 mb-lg-7">${section.dataset.navTitle}</h4>
            <p>${section.dataset.navDescription || ''}</p>
          </div>
        `;
            })
            .join('');

        this.navItems = Array.from(nav.children);

        this.navItems.forEach((navItem, index) => {
            navItem.addEventListener('click', () => {
                const targetSection = this.sections[index];
                if (!targetSection) return;

                const rect = targetSection.getBoundingClientRect();
                const scrollTop = window.scrollY + rect.top;
                window.scrollTo({
                    top: scrollTop,
                    behavior: 'smooth',
                });
            });
        });
    }

    setupScrollTriggers() {
        if (window.innerWidth < 768) {
            return;
        }

        ScrollTrigger.create({
            trigger: this.blockElementList,
            start: "top 20%",
            end: "bottom center",
            pin: this.blockElementList,
            pinSpacing: false,
        });
        // this.sections.forEach((section, index) => {
        //     ScrollTrigger.create({
        //         trigger: section,
        //         start: "top 50%",
        //         end: "bottom 50%",
        //         markers: true,
        //         onEnter: () => this.activateSection(index),
        //         onEnterBack: () => this.activateSection(index),
        //     });
        // });


        this.navItems.forEach((navItem, index) => {
            ScrollTrigger.create({
                trigger: navItem,
                start: "10% center",
                end: "bottom center",
                onEnter: () => this.activateSection(index),
                onEnterBack: () => this.activateSection(index),
            });
        });
    }

    deactivateSection(index) {
        this.sections[index]?.classList.remove('active-content');
        this.navItems[index]?.classList.remove('active');
    }

    activateSection(index) {
        this.sections.forEach((section, i) => {
            section.classList.toggle('active-content', i === index);
        });

        this.navItems.forEach((navItem, i) => {
            navItem.classList.toggle('active', i === index);
        });

        const activeNavItem = this.navItems[index];
        if (activeNavItem && this.navContainer) {
            const itemOffsetTop = activeNavItem.offsetTop;
            gsap.to(this.navContainer, {
                scrollTop: itemOffsetTop,
                duration: 0.5,
                ease: "power2.out",
            });
        }
    }
}