export default function initializeHeader() {
    const header = document.querySelector('.header');
    const scrolledClass = 'scrolled';
    const hiddenClass = 'hidden';
    const scrollThreshold = 50;
    const delay = 200; // Czas opóźnienia w ms, po którym ukrywa się nagłówek

    let lastScrollY = 0;
    let hideTimeout;

    const updateHeaderClass = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > scrollThreshold) {
            header.classList.add(scrolledClass);
        } else {
            header.classList.remove(scrolledClass);
            header.classList.remove(hiddenClass);
            clearTimeout(hideTimeout);
        }

        lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', updateHeaderClass);
    updateHeaderClass();
}
