import initializeAnchor from './anchors';
import initializeMobileMenu from './menu';
import initializeFiles from './blocks/files';
import initializeFancyBox from './fancybox';
import initializeForm from './utils/form';
import initializeHeader from "./utils/header";
import initializeBoxSlider from "./blocks/box-slider";
import initializeNumbersBlock from "./blocks/numbers";
import initializeScrollBoxText from "./blocks/scroll-box-text";
import initializeFloatingBoxes from "./blocks/floating-boxes";
import initializeAccordions from "./blocks/accordion";
import initializeCubeSlider from "./blocks/cube-slider";
import initializeVentiTabs from "./blocks/venti-tabs";
import initializeRealizationList from "./blocks/realization-list";
import initializeGallerySlider from "./blocks/gallery-slider";
import initializeScrollSections from "./blocks/scroll-sections";
import initializeAnimatedTitle from "./blocks/animated-title";
import initializeLogotypes from "./blocks/logotypes";
import initializeShowNumberBlock from "./blocks/show-number";
import initializeVideoLazyLoad from "./utils/video-lazy-load";

class App {
    constructor() {
        this.initialize();
    }

    initialize() {
        import('aos/dist/aos.js').then(({ default: AOS }) => {
            AOS.init({
                startEvent: 'load',
                disable: 'mobile',
                once: true,
                duration: 1000
            });
        });
        initializeForm();
        initializeFancyBox();
        initializeMobileMenu();
        initializeFiles();
        initializeHeader();
        initializeBoxSlider();
        initializeNumbersBlock();
        initializeFloatingBoxes();
        initializeScrollBoxText();
        initializeAccordions();
        initializeCubeSlider();
        initializeVentiTabs();
        initializeRealizationList();
        initializeGallerySlider();
        initializeAnimatedTitle();
        initializeScrollSections();
        initializeLogotypes();
        initializeShowNumberBlock();
        initializeVideoLazyLoad();
        initializeAnchor();
    }
}

function initializeApp() {
    const app = new App();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeApp);
} else {
    initializeApp();
}

document.querySelectorAll('.lazy-image').forEach((image) => {
    if (image.complete) {
        image.classList.add('loaded');
    } else {
        image.addEventListener('load', () => {
            image.classList.add('loaded');
        });
    }
});
