export default function initializeRealizationList() {
    document.querySelectorAll('.block-realization-list-other').forEach(function (block) {
        new RealizationList(block);
    });
}

class RealizationList {
    block = null;
    baseHref = null;
    params = {
        list_page: 1,
        tax_type: null,
        loader: null,
        per_load: 1,
    };
    listDOM = null;
    preloaderDOM = null;
    debugDOM = null;
    loadMoreButton = null;

    constructor(block) {
        this.block = block;
        this.listDOM = block.querySelector('.block-realization-list-other__list');
        this.preloaderDOM = block.querySelector('.block-realization-list-other__loader');
        this.loadMoreButton = block.querySelector('.block-realization-list-other__load-more');
        this.debug = block.querySelector('.debug');
        this.baseHref = window.location;
        this.params.per_load = this.debug.dataset.perLoad || 2;
        this.params.lang = this.block.getAttribute('data-lang');
        this.params.tax_type = this.block.querySelector('.block-realization-list-other__filter-link.is-active')?.dataset?.slug || null;
        this.initializeActions();
        this.loadOffers(true);
    }

    initializeActions() {
        this.block.addEventListener('click', (e) => {
            if (e.target.classList.contains('block-realization-list-other__filter-link')) {
                e.preventDefault();
                this.handleCategoryChange(e.target);
            }

            if (e.target.classList.contains( 'block-realization-list-other__load-more' )) {
                e.preventDefault();
                this.loadOffers();
            }
        });
    }

    handleCategoryChange(target) {
        const clickedCategory = target.dataset.slug || null;

        if (this.params.tax_type === clickedCategory) {
            this.params.tax_type = null;
            this.params.list_page = 1;
            this.listDOM.innerHTML = '';
            this.loadOffers(true);
            this.clearActiveCategory();
        } else {
            this.params.tax_type = clickedCategory;
            this.params.list_page = 1;
            this.listDOM.innerHTML = '';
            this.loadOffers(true);
            this.updateActiveCategory(target);
        }
    }

    clearActiveCategory() {
        this.block.querySelectorAll('.block-realization-list-other__filter-link').forEach((link) => {
            link.classList.remove('is-active');
        });
    }

    updateActiveCategory(target) {
        this.clearActiveCategory();
        target.classList.add('is-active');
    }


    loadOffers(clear = false) {
        this.loadMoreButton.classList.add('button--disabled');
        this.loadMoreButton.disabled = true;

        if (clear) {
            this.listDOM.innerHTML = '';
        }
        this.preloader(true);

        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${this.baseHref.origin}/wp-json/odyssey/v1/realization/?params=${encodeURIComponent(JSON.stringify(this.params))}`);
        xhr.send();

        xhr.onload = () => {
            if (xhr.status === 200) {
                try {
                    const response = JSON.parse(xhr.responseText);
                    if (clear) {
                        this.listDOM.innerHTML = response.content;
                    } else {
                        this.listDOM.innerHTML += response.content;
                    }

                    if (!response.has_more) {
                        this.loadMoreButton.classList.add('button--disabled');
                        this.loadMoreButton.disabled = true;
                    } else {
                        this.loadMoreButton.classList.remove('button--disabled');
                        this.loadMoreButton.disabled = false;
                    }

                    this.params.list_page += 1;
                } catch (error) {
                    console.error('Error parsing response:', error);
                }
            } else {
                console.error(`Error ${xhr.status}: ${xhr.statusText}`);
            }

            this.preloader(false);
        };

        xhr.onerror = () => {
            console.error('Request failed');
            this.preloader(false);
        };
    }

    preloader(status) {
        if (status) {
            this.preloaderDOM.classList.remove('d-none');
            this.listDOM.classList.add('loading');
        } else {
            this.preloaderDOM.classList.add('d-none');
            this.listDOM.classList.remove('loading');
        }
    }
}
