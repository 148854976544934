export default function initializeBoxSlider() {
  document.querySelectorAll('.block-box-slider').forEach((block) => {
    if (block.querySelector('.block-box-slider__swiper')) {
      new BoxSlider(block);
    }
  });
}

class BoxSlider {
  constructor(block) {
    this.block = block;
    const blockSection = this.block.closest('section[id^="block-"]');
    this.blockId = blockSection ? blockSection.id.replace('block-', '') : null;
    this.modal = document.querySelector(`#modal-${this.blockId}`);
    this.init();
  }

  async init() {
    try {
      const { default: Swiper } = await import('swiper/bundle');
      this.initSwiper(Swiper);
      this.addClickHandlers();

      let resizeTimer;
      window.addEventListener('resize', () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => this.initSwiper(Swiper), 200);
      });
    } catch (error) {
      console.error('Błąd podczas ładowania Swiper.js:', error);
    }
  }

  initSwiper(Swiper) {
    const slider = this.block.querySelector('.block-box-slider__swiper');
    const pagination = this.block.querySelector('.swiper-pagination');

    if (!slider || !pagination) {
      console.warn('Brak elementu .block-box-slider__swiper lub .swiper-pagination w bloku:', this.block);
      return;
    }

    const blockSwiperArgs = {
      speed: 1000,
      direction: 'horizontal',
      navigation: {
        nextEl: this.block.querySelector('.block-box-slider__arrow--next'),
        prevEl: this.block.querySelector('.block-box-slider__arrow--prev'),
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
    };

    if (this.block.dataset.swiperConfig) {
      const config = JSON.parse(this.block.dataset.swiperConfig);
      Object.assign(blockSwiperArgs, config);

      if (this.block.dataset.swiperCustomConfig) {
        const customConfig = JSON.parse(this.block.dataset.swiperCustomConfig);
        Object.assign(blockSwiperArgs, customConfig);
      }
    }

    new Swiper(slider, blockSwiperArgs);
  }

  addClickHandlers() {
    const slides = this.block.querySelectorAll('.swiper-slide');

    slides.forEach((slide) => {
      slide.addEventListener('click', async () => {
        if (this.modal) {
          const modalBody = this.modal.querySelector('.modal-body');
          if (modalBody) {
            const innerContent = slide.querySelector('.block-box-slider-item__inner-content');

            if(innerContent.innerHTML) {
              modalBody.innerHTML = innerContent ? innerContent.innerHTML : '';
              try {
                const {Modal} = await import('bootstrap');
                const bootstrapModal = Modal.getOrCreateInstance(this.modal);
                bootstrapModal.show();
              } catch (error) {
                console.error('Błąd przy otwieraniu modala:', error);
              }
            }
          }
        }
      });
    });
  }
}
