export default function initializeAllCollapsesFile() {
    document.querySelectorAll('[data-id-collapse-file]').forEach(function (block) {
        new FileCollapse(block);
    });
}

class FileCollapse {
    constructor(block) {
        this.block = block;
        this.init();
    }

    init() {
        const accordionItems = this.block.querySelectorAll('.block-files__collapse-item');
        const buttonShow = this.block.querySelector('.block-files__accordion-toggle');

        buttonShow.addEventListener('click', () => {
            let firstHiddenItem = null;
            accordionItems.forEach(item => {
                if (item.classList.contains('d-none') && !firstHiddenItem) {
                    firstHiddenItem = item;
                }
                item.classList.remove('d-none');
                item.classList.add('d-block');
            });
            if (firstHiddenItem) {
                firstHiddenItem.scrollIntoView({behavior: "smooth"});
            }
            buttonShow.classList.add('d-none');
        });
    }
}
