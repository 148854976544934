import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function initializeScrollBoxText() {
    document.querySelectorAll('.block-scroll-box-text').forEach(block => {
        new ScrollBoxText(block);
    });
}

class ScrollBoxText {
    constructor(block) {
        this.blockDom = block;
        this.cards = gsap.utils.toArray(this.blockDom.querySelectorAll('.block-scroll-box-text__card'));
        this.container = this.blockDom.querySelector('.block-scroll-box-text__container');
        this.images = this.blockDom.querySelectorAll('img'); // Pobierz wszystkie obrazy w bloku
        this.resizeObserver = null; // ResizeObserver
        this.scrollTriggers = []; // Przechowujemy instancje ScrollTrigger
        this.init();
    }

    init() {
        this.setupScrollTriggers();

        // Monitoruj załadowanie obrazów
        this.monitorImageLoad();

        // Monitoruj zmiany wielkości bloku
        this.observeResize();
    }

    setupScrollTriggers() {
        // Usuń ScrollTriggers powiązane z tym komponentem
        this.cleanupScrollTriggers();

        const lastCard = this.cards[this.cards.length - 1];

        this.cards.forEach((card, index) => {
            const isLast = index === this.cards.length - 1;
            const halfHeight = isLast ? 0 : lastCard.offsetHeight / 2;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: card,
                    start: "center 40%",
                    end: "bottom 20%",
                    scrub: true,
                    markers: false,
                    preventDefault: true,
                    onUpdate: (self) => {
                        const progress = self.progress;
                        const scaleValue = 1 - progress * (isLast ? 0 : 0.3);
                        gsap.to(card, {
                            scale: scaleValue,
                            duration: 0.1,
                            overwrite: true,
                        });
                    },
                }
            });

            // Zachowujemy odniesienie do utworzonego ScrollTrigger
            this.scrollTriggers.push(tl.scrollTrigger);

            const st = ScrollTrigger.create({
                trigger: card,
                start: `center 45%`,
                end: `bottom-=${halfHeight} center`,
                endTrigger: this.container,
                pin: !isLast,
                markers: false,
                preventDefault: true,
            });

            // Zachowujemy odniesienie do utworzonego ScrollTrigger
            this.scrollTriggers.push(st);
        });
    }

    monitorImageLoad() {
        // Dodaj listener dla każdego obrazu
        this.images.forEach(image => {
            if (!image.complete) {
                image.addEventListener('load', () => this.refreshScrollTriggers());
                image.addEventListener('error', () => this.refreshScrollTriggers());
            }
        });
    }

    observeResize() {
        // Użyj ResizeObserver do monitorowania zmian wielkości kontenera
        this.resizeObserver = new ResizeObserver(() => {
            this.refreshScrollTriggers();
        });

        this.resizeObserver.observe(this.blockDom);
    }

    refreshScrollTriggers() {
        this.cards = gsap.utils.toArray(this.blockDom.querySelectorAll('.block-scroll-box-text__card'));
        this.setupScrollTriggers();
    }

    cleanupScrollTriggers() {
        // Usuń ScrollTriggers powiązane z tym komponentem
        this.scrollTriggers.forEach(trigger => trigger.kill());
        this.scrollTriggers = [];
    }

    destroy() {
        // Zatrzymaj ResizeObserver
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        // Usuń ScrollTriggers powiązane z tym komponentem
        this.cleanupScrollTriggers();
    }
}


