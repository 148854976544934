export default function initializeForm() {
    document.querySelectorAll( '.custom-form' ).forEach( function( block ) {
        new ContactFormClass( block );
    } );
}

class ContactFormClass {
    constructor( block ) {
        this.block = block;
        this.init();
    }

    init() {
        this.hoverField();
    }

    hoverField() {
        const self = this;

        // Dodanie obsługi zdarzenia focus dla elementów input wewnątrz elementów z klasą 'js-form-item'
        document.querySelectorAll('.custom-form .form-field-container input, .custom-form .form-field-container select, .custom-form .form-field-container textarea').forEach(function(input) {
            if(input.value !== '') {
                input.parentNode.parentNode.classList.add('form-field-container--filled');
            }

            input.addEventListener('focus', function() {
                this.parentNode.parentNode.classList.add('form-field-container--filled');
                this.parentNode.parentNode.classList.add('form-field-container--focused');
                self.notValidateClass();
                console.log(self);
            });

            input.addEventListener('blur', function() {
                if(this.value === '') {
                    this.parentNode.parentNode.classList.remove(
                        'form-field-container--filled' );
                    console.log('filled');
                }
                this.parentNode.parentNode.classList.remove(
                    'form-field-container--focused' );
                console.log('focused');
                // wykonaj funkcję sprawdzającą czy pola są wypełnione notValidateClass()
                self.notValidateClass();
            });

        });

    }

    notValidateClass() {
        document.querySelectorAll('.custom-form .form-field-container input, .custom-form .form-field-container select, .custom-form .form-field-container textarea').forEach(function(input) {
            if(input.ariaInvalid === 'true') {
                input.parentNode.parentNode.classList.add('form-field-container--invalid');
            } else {
                input.parentNode.parentNode.classList.remove('form-field-container--invalid');
            }
        });
    }
}
