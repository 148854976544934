
export default function initializeNumbersBlock() {
    document.querySelectorAll('.block-numbers').forEach(function(block) {
        new NumbersBlock(block);
    });
}

class NumbersBlock {
    constructor(block) {
        this.block = block;
        this.init();
    }

    init() {
        this.block.querySelectorAll('.block-numbers__item__number').forEach(number => {
            let numbersStarted = false;

            if (this.isElementInViewport(number)) {
                numbersStarted = this.startNumbersAnimation(number, numbersStarted);
            }

            window.addEventListener('scroll', () => {
                if (this.isElementInViewport(number)) {
                    numbersStarted = this.startNumbersAnimation(number, numbersStarted);
                }
            }, false);
        });
    }

    isElementInViewport(el) {
        let rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    startNumbersAnimation(number, numbersStarted) {
        if (numbersStarted === false) {
            numbersStarted = true;
            this.animateValue(number, 0, parseInt(number.dataset.number), 3000);
        }

        return numbersStarted;
    }

    animateValue(obj, start, end, duration) {
        if (start === end) return;

        let range = Math.abs(end - start),
            increment = Math.ceil(range / 500),
            current = start,
            direction = start < end ? 1 : -1,
            stepTime = Math.abs(Math.floor(duration / range));

        let timer = setInterval(() => {
            current += increment * direction;
            obj.innerHTML = current;
            if ((direction === 1 && current >= end) || (direction === -1 && current <= end)) {
                obj.innerHTML = end;
                clearInterval(timer);
            }
        }, stepTime);
    }
}