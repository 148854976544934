export default function initializeLogotypes() {

    if (document.querySelectorAll('.logotypes-slider:not(.logotypes-slider--disabled)').length === 0) {return;}

    import('swiper/bundle').then(({ default: Swiper }) => {
        document.querySelectorAll('.logotypes-slider:not(.logotypes-slider--disabled)').forEach(function(slider) {
            const swiper = new Swiper( slider, {
                speed: 600,
                loop: true,
                spaceBetween: 2,
                autoplay: {
                    delay: 3000,
                    reverseDirection: slider.dataset.side === 'right' ? true : false,
                    disableOnInteraction: true,
                },
                navigation: {
                    nextEl: slider.querySelector( '.logotypes-slider__arrow--next' ),
                    prevEl: slider.querySelector( '.logotypes-slider__arrow--prev' ),
                },
                breakpoints: {
                    0: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: Math.ceil( parseInt( slider.dataset.cols ) / 2 ),
                        loopAdditionalSlides: 0,
                    },
                    1200: {
                        slidesPerView: parseInt( slider.dataset.cols ),
                    },
                },
            } );
        });
    });
}
