import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class UniversalSmoothScroll {
    constructor() {
        const header = document.querySelector('.header')
            ? document.querySelector('.header').offsetHeight
            : 0;
        const submenu = document.querySelector('.venti-tabs--menu .venti-tabs__nav-container')
            ? document.querySelector('.venti-tabs--menu .venti-tabs__nav-container').offsetHeight
            : 0;

        this.offset = header + submenu;
        this.duration = 1;
        this.ease = 'power2.out';

        this.gsapInstance = gsap;

        /**
         * ZAPAMIĘTUJEMY hash (jeśli istnieje),
         * żeby zapobiec automatycznemu scrollowaniu przeglądarki.
         */
        this.initialHash = window.location.hash ? window.location.hash.substring(1) : null;

        if (this.initialHash) {
            history.replaceState(
                {},
                document.title,
                window.location.pathname + window.location.search
            );
        }

        window.addEventListener('load', () => {
            if (this.initialHash) {
                this.scrollToAnchor(this.initialHash);
            }
        });
        this.bindAnchors(document);
    }

    /**
     * Metoda do płynnego przewinięcia do podanego ID (bez #).
     */
    scrollToAnchor(anchorID) {
        // jeżeli ID zaczyna się od mm- to nie scrollujemy
        if (anchorID.startsWith('mm-')) return;

        const targetEl = document.getElementById(anchorID);
        if (!targetEl) return;

        // animacja GSAP
        this.gsapInstance.to(window, {
            duration: this.duration,
            scrollTo: {
                y: `#${anchorID}`,
                offsetY: this.offset
            },
            ease: this.ease
        });
    }


    /**
     * Przegląda wybrany kontener i binduje *wszystkie* linki z atrybutem href^="#".
     */
    bindAnchors(container) {
        const anchors = container.querySelectorAll('a[href^="#"]');
        anchors.forEach(anchor => this.bindAnchor(anchor));
    }

    /**
     * Dodaje event listener do konkretnego linka, tak by kliknięcie
     * wywołało płynne przewinięcie przez GSAP zamiast klasycznego jumpa.
     */
    bindAnchor(anchor) {
        if (anchor.__smoothScrollBound) return;
        anchor.__smoothScrollBound = true;

        anchor.addEventListener('click', (e) => {
            const href = anchor.getAttribute('href');
            const anchorID = href.substring(1);

            // Jeśli link prowadzi do #mm-, to zignoruj go (nie rób smooth scrolla)
            if (anchorID.startsWith('mm-')) {
                return;
            }

            // Reszta logiki
            e.preventDefault();
            this.scrollToAnchor(anchorID);
        });
    }


    /**
     * Opcjonalna obserwacja zmian w DOM – jeżeli w Twojej aplikacji
     * linki pojawiają się dynamicznie po AJAX-ie itp.
     * możesz to włączyć, aby zawsze je "zbindować" do smooth scrolla.
     */
    initObserver() {
        this.observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === 1) {
                        if (node.matches && node.matches('a[href^="#"]')) {
                            this.bindAnchor(node);
                        }
                        if (node.querySelectorAll) {
                            const anchors = node.querySelectorAll('a[href^="#"]');
                            anchors.forEach((anchor) => this.bindAnchor(anchor));
                        }
                    }
                });
            }
        });

        this.observer.observe(document.body, { childList: true, subtree: true });
    }
}

export default function initializeAnchor() {
    new UniversalSmoothScroll();
}
