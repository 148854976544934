export default function initializeAccordions() {
    document.querySelectorAll( '.block-accordion' ).forEach( function( block ) {
        new Accordion(block);
    } );
}

class Accordion {
    constructor(block) {
        this.block = block;
        this.init();
    }
    init() {
        const accordionItems = this.block.querySelectorAll('.accordion-item');
        const AccordionScrollOffset = 0; // domyślne odsunięcie od góry w pixelach



        accordionItems.forEach(item => {
            const header = item.querySelector('.accordion-item__header');
            const content = item.querySelector('.accordion-item__content');
            header.style.scrollMargin = `${AccordionScrollOffset}px`;

            header.addEventListener('click', () => {
                item.classList.toggle('active');

                if (item.classList.contains('active')) {
                    header.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        });
    }
}