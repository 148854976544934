import Mmenu from 'mmenu-js';
import 'mburger-webcomponent/dist/mburger/index';

export default function initializeMobileMenu() {

    new Mmenu( '#mobile-menu', {
        theme: 'black',
    } );

}
