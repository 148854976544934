import gsap from 'gsap';

export default function initializeShowNumberBlock() {
    document.querySelectorAll('.block-show-number').forEach(block => {
        new ShowNumber(block);
    });
}

class ShowNumber {
    constructor(block) {
        this.blockDom = block;
        this.button = this.blockDom.querySelector('.block-show-number__show');
        this.phoneNumberContainer = this.blockDom.querySelector('.block-show-number__number');
        this.encodedNumber = this.blockDom.getAttribute('data-tel-code');
        this.encodedTitle = this.blockDom.getAttribute('data-tel-title');

        if (this.button && this.encodedNumber) {
            this.init();
        }
    }

    init() {
        this.button.addEventListener('click', (event) => {
            event.preventDefault();
            this.revealNumber();
        });
    }

    revealNumber() {
        try {
            if (!this.encodedNumber) throw new Error('Brak danych do dekodowania');

            // Dekodowanie HEX + przesunięcie znaków ASCII (-3)
            const decodedNumber = this.simpleDecode(this.encodedNumber);
            const decodedTitle = this.encodedTitle ? this.simpleDecode(this.encodedTitle) : '';

            if (!decodedNumber) throw new Error('Nie udało się odszyfrować numeru');

            // Tworzenie linku
            const linkElement = document.createElement('a');
            linkElement.href = decodedNumber;
            linkElement.textContent = decodedTitle || decodedNumber;
            linkElement.target = "_blank";

            this.phoneNumberContainer.innerHTML = '';
            this.phoneNumberContainer.appendChild(linkElement);

            // Animacja obrotu
            gsap.to(this.button, {
                rotateY: 90,
                duration: 0.3,
                ease: 'power2.in',
                onComplete: () => {
                    this.button.style.display = 'none';
                    this.phoneNumberContainer.classList.remove('d-none');

                    gsap.fromTo(
                        this.phoneNumberContainer,
                        { rotateY: -90, opacity: 0 },
                        { rotateY: 0, opacity: 1, duration: 0.3, ease: 'power2.out' }
                    );
                },
            });
        } catch (error) {
            console.error('Błąd dekodowania numeru telefonu:', error);
        }
    }

    simpleDecode(hex) {
        let decoded = '';
        for (let i = 0; i < hex.length; i += 2) {
            let charCode = parseInt(hex.substr(i, 2), 16) - 3;
            decoded += String.fromCharCode(charCode);
        }
        return decoded;
    }
}

