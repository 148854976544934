import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function initializeBigTitle() {
    document.querySelectorAll('.block-animated-title').forEach(block => {
        new AnimatedTitle(block);
    });
}



class AnimatedTitle {
    constructor(block) {
        this.blockDom = block;
        this.init();
        ScrollTrigger.refresh();
    }

    init() {

        gsap.to(this.blockDom, {
            scrollTrigger: {
                trigger: this.blockDom,
                start: 'center center',
                end: '+='+this.blockDom.offsetHeight,
                pin: true,
                scrub: true,
            },
        });
    }
}
