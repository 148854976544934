export default function initializeVideoLazyLoad() {
    document.querySelectorAll('.lazy-loading-video').forEach(video => {
        new LazyLoadVideo(video);
    });
}

class LazyLoadVideo {
    constructor(video) {
        this.videoElement = video;
        this.sourceElement = this.videoElement.querySelector("source");

        if (this.sourceElement && this.sourceElement.dataset.src) {
            this.init();
        }
    }

    init() {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadVideo();
                    observer.unobserve(this.videoElement);
                }
            });
        }, { threshold: 0.3 });

        observer.observe(this.videoElement);
    }

    loadVideo() {
        try {
            this.sourceElement.src = this.sourceElement.dataset.src;
            this.videoElement.load();
        } catch (error) {
            console.error("Błąd ładowania wideo:", error);
        }
    }
}
