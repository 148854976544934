

export default function initializeFancybox() {
    new BlockFancybox();
}

class BlockFancybox {
    constructor() {
        this.init();
    }

    init() {
        if(document.querySelectorAll('[data-fancybox]').length === 0) {
            return;

        }
        return import('@fancyapps/ui').then(({Fancybox}) => {
            Fancybox.bind('[data-fancybox]', {
            });
        });

    }
}