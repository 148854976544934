class CubeSlider {
  constructor(block) {
    this.block = block;
    this.init();
  }

  async init() {
    try {
      const { default: Swiper } = await import('swiper/bundle');

      new Swiper('#cube-slider-left', {
        effect: "cube",
        grabCursor: false,
        loop: true,
        allowTouchMove: false,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 10,
          shadowScale: 0.50,
        },
        navigation: {
          nextEl: '.block-cube-slider__arrow--next',
          prevEl: '.block-cube-slider__arrow--prev',
        },
      });

      new Swiper('#fade-slider-right', {
        effect: 'fade',
        loop: true,
        allowTouchMove: false,
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          nextEl: '.block-cube-slider__arrow--next',
          prevEl: '.block-cube-slider__arrow--prev',
        },
      });
    } catch (error) {
      console.error('Błąd podczas ładowania Swiper.js:', error);
    }
  }
}

export default function initializeCubeSlider() {
  document.querySelectorAll('.block-cube-slider').forEach((block) => {
    new CubeSlider(block);
  });
}
