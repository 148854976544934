import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function initializeFloatingBoxes() {
    document.querySelectorAll('.block-floating-boxes').forEach(block => {
        new FloatingBoxes(block);
    });
}

class FloatingBoxes {
    constructor(block) {
        this.blockDom = block;
        this.staticContent = block.querySelector('.block-floating-boxes__static-content');
        this.wrapper = block.querySelector('.block-floating-boxes__container');
        this.cards = block.querySelectorAll('.block-floating-boxes__card');
        this.init();
    }

    init() {
        if (!this.blockDom || !this.staticContent || !this.wrapper) {
            console.error('Brak wymaganych elementów!');
            return;
        }

        this.animateCards();
    }

    animateCards() {
        this.cards.forEach(box => {
            const title = box.querySelector('.block-floating-boxes__title');
            const description = box.querySelector('.block-floating-boxes__description');
            const button = box.querySelector('.block-floating-boxes__button');

            gsap.from([title, description, button], {
                opacity: 0,
                y: 50,
                duration: 0.8,
                stagger: 0.2,
                scrollTrigger: {
                    trigger: box,
                    start: 'top 60%',
                    end: 'top 20%',
                    toggleActions: 'play none none reverse',
                    markers: false,
                    preventDefault: true,
                },
            });
        });
    }
}
