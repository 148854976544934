export default function initializeGallerySlider() {
  document.querySelectorAll('.block-gallery-slider').forEach((block) => {
    new GallerySlider(block);
  });
}

class GallerySlider {
  constructor(block) {
    this.block = block;
    this.init();
  }

  async init() {
    try {
      const { default: Swiper } = await import('swiper/bundle');

      new Swiper(this.block.querySelector('.block-gallery-slider__swiper'), {
        slidesPerView: 'auto',
        speed: 1300,
        loop: false,
        freeMode: false,
        navigation: {
          nextEl: this.block.querySelector('.block-gallery-slider__arrow--next'),
          prevEl: this.block.querySelector('.block-gallery-slider__arrow--prev'),
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
      });

    } catch (error) {
      console.error('Błąd podczas ładowania Swiper.js:', error);
    }
  }
}
