export default function initializeVentiTabs() {
    document.querySelectorAll('.venti-tabs').forEach((block) => {
        new VentiTabsClass(block);
    });
}

class VentiTabsClass {
    constructor(block) {
        this.block = block;
        this.navItems = [];
        this.init();
    }

    init() {
        Array.from(this.block.children).forEach((child) => {
            if (
                child.tagName.toLowerCase() === 'section' &&
                child.hasAttribute('data-nav-title')
            ) {
                this.navItems.push(child);
            }
        });
        if (this.navItems.length === 0) {
            return;
        }

        this.nav = this.block.querySelector('.venti-tabs__nav');
        this.renderNav();

        const firstNavItem = this.nav.querySelector('.venti-tabs__nav-item');
        if (firstNavItem) {
            this.activateTab(firstNavItem);
        }

        this.blockListeners();

        if (this.block.classList.contains('venti-tabs--menu')) {
            this.watchScroll();
        }
        
        this.updateStickyNavPosition();
        window.addEventListener('resize', () => this.updateStickyNavPosition());
        window.addEventListener('resize', () => this.runIndicator(this.nav.querySelector('.venti-tabs__nav-item.active')));

        if(this.nav.querySelector('.venti-tabs__nav-item.active')){
            this.runIndicator(this.nav.querySelector('.venti-tabs__nav-item.active'));
        }
    }

    renderNav() {
        this.nav.innerHTML = this.navItems
            .map((section) => {
                const title = section.dataset.navTitle || '';
                const anchor = section.dataset.navAnchor || '';
                const imageUrl = section.dataset.navImageUrl || '';

                if (title === '') {
                    return '';
                }

                if (!anchor) {
                    if (imageUrl) {
                        return `
                          <div class="venti-tabs__nav-item"
                               data-target="${section.id}"
                               role="link">
                            <img loading="lazy" src="${imageUrl}" alt="${title}">${title}
                          </div>`;
                    }
                    return `
                      <div class="venti-tabs__nav-item"
                           data-target="${section.id}"
                           role="link">
                        ${title}
                      </div>`;
                } else {
                    if (imageUrl) {
                        return `
                          <a class="venti-tabs__nav-item"
                             data-target="${section.id}"
                             href="#${anchor}">
                            <img loading="lazy" src="${imageUrl}" alt="${title}">${title}
                          </a>`;
                    }
                    return `
                      <a class="venti-tabs__nav-item"
                         data-target="${section.id}"
                         href="#${anchor}">
                        ${title}
                      </a>`;
                }
            })
            .join('');

        this.actionsNav();
    }

    actionsNav() {
        this.nav.addEventListener('click', (e) => {
            const clickedItem = e.target.closest('.venti-tabs__nav-item');
            if (!clickedItem) return;

            if (!this.block.classList.contains('venti-tabs--menu')) {
                e.preventDefault();
                this.activateTab(clickedItem);
            }
        });
    }

    updateStickyNavPosition() {
        const header = document.querySelector('.header');
        if (!header) return;

        const headerHeight = header.offsetHeight;
        const stickyNav = this.block.querySelector('.venti-tabs__nav-container--menu');

        if (stickyNav) {
            stickyNav.style.top = `${headerHeight}px`;
        }
    }


    activateTab(target) {
        const navLinks = this.nav.querySelectorAll('.venti-tabs__nav-item');
        navLinks.forEach((link) => link.classList.remove('active'));

        target.classList.add('active');

        this.navItems.forEach((section) => {
            section.classList.remove('active', 'non-visible');
        });

        const matchedSection = this.block.querySelector(
            `#${target.dataset.target}`
        );
        if (matchedSection) {
            matchedSection.classList.add('non-visible', 'active');
            setTimeout(() => {
                matchedSection.classList.remove('non-visible');
            }, 400);
        }

        this.runIndicator(target);
    }

    runIndicator(target) {
        const indicator = this.block.querySelector('.venti-tabs__nav-indicator');
        if (!indicator) return;
        indicator.style.width = target.offsetWidth + 'px';
        indicator.style.left = target.offsetLeft + 'px';
    }

    blockListeners() {
        window.addEventListener('activate_tab', (e) => {
            if (e.detail.target) {
                const targetElement = this.block.querySelector(
                    '.venti-tabs__nav-item[data-target="' + e.detail.target + '"]'
                );
                if (!targetElement) return;
                targetElement.click();
            }
        });
    }

    watchScroll() {
        const offset = 150;
        const header = document.querySelector('.header');
        if (!header) return;

        const stickyNav = this.block.querySelector('.venti-tabs__nav-container');
        if (!stickyNav) return;

        const initialTop = stickyNav.offsetTop;

        window.addEventListener('scroll', () => {
            const scrollPosition = window.scrollY;
            const headerHeight = header.offsetHeight;

            if (scrollPosition >= initialTop - headerHeight) {
                stickyNav.style.position = 'sticky';
                stickyNav.style.top = `${headerHeight}px`;
            } else {
                stickyNav.style.position = '';
                stickyNav.style.top = '';
            }

            let currentSectionId = null;
            this.navItems.forEach(section => {
                if (section.offsetTop <= scrollPosition + offset) {
                    currentSectionId = section.id;
                }
            });

            if (currentSectionId) {
                const navItem = this.nav.querySelector(`.venti-tabs__nav-item[data-target="${currentSectionId}"]`);
                if (navItem && !navItem.classList.contains('active')) {
                    this.activateTab(navItem);
                }
            }
        });
    }

}
